





import {Vue, Component} from "vue-property-decorator";
import FriendRecommendType from "./FriendRecommend";

@Component({name: "FriendRecommend"})
export default class FriendRecommend extends Vue implements FriendRecommendType{
    
}
